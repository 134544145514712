<template>
  <div v-if="showComment" class="post-comment">
    <div class="post-comment-primary">
      <div class="post-comment-avatar-wrapper">
        <app-avatar
          v-router-link="{ name: 'Profile', params: comment.user }"
          :picture="comment.user.profilePhotoURL"
          :name="userDisplayName"
          size="small"
          class="post-comment-avatar"
        >
        </app-avatar>
      </div>
      <div class="post-comment-body">
        <div class="post-comment-body-message">
          <div class="post-comment-body-title">
            <div
              v-router-link="{ name: 'Profile', params: comment.user }"
              class="flex-1 cs-textstyle-paragraph-bold"
            >
              <app-username :user="comment.user" type="displayname" />
            </div>
            <i class="cs-icons-options text-light" @click="showMenu = true"></i>
          </div>
          <div
            class="
              post-comment-body-text
              cs-textstyle-paragraph
              selectable-text
              pre-line
            "
            @click="redirectToPostDetailedPage"
          >
            <span v-for="(data, i) in displayBody" :key="i">
              <!-- Normal text in body -->
              <span v-if="data.type === 'text'">{{ data.word }} </span>
              <!-- mentions in comment boby -->
              <span
                v-if="data.type === 'mention'"
                v-router-link="{
                  name: 'UProfile',
                  params: { username: data.word.replace('@', '') },
                }"
                class="ch-user-mention ch-user-mention--comment"
                >{{ data.word }}
              </span>
            </span>
          </div>
          <img
            v-if="isTranslatingComment"
            src="@/assets/images/translation_icon-orange.svg"
            class="translate-button"
            @click="showTranslatedComment"
          />
          <div v-if="isHidingTranslateComment" class="ch-translation-wrapper">
            <img
              src="@/assets/images/translation_icon-orange.svg"
              class="translate-button"
              @click="hideTranslatedComment"
            />
            <div class="cs-textstyle-paragraph-small message-margin">
              <span v-for="(data, i) in displayTranslatedBody" :key="i">
                <!-- Normal text in body -->
                <span v-if="data.type === 'text'">{{ data.word }} </span>
                <!-- mentions in comment boby -->
                <span
                  v-if="data.type === 'mention'"
                  v-router-link="{
                    name: 'UProfile',
                    params: { username: data.word.replace('@', '') },
                  }"
                  class="ch-user-mention ch-user-mention--comment"
                  >{{ data.word }}
                </span>
              </span>
            </div>
            <app-spinner
              v-if="loadingTranslation"
              size="small"
              class="ch-spinner"
            />
          </div>
          <div>
            <app-post-media
              v-if="
                (comment.images && comment.images.length) || comment.linkPreview
              "
              :images="comment.images"
              :link-preview="comment.linkPreview"
            />
          </div>
        </div>
        <div
          class="cs-textstyle-paragraph-small cs-gray-04 comment-response-bar"
        >
          <span
            :id="comment.id + '.reactbutton'"
            class="capitalize cs-textstyle-paragraph-small-bold cursor-pointer"
            @click="reactionClickHandle"
          >
            <i
              v-if="!comment.myReaction"
              class="ch-icons-reaction post-comment-action-icon"
            ></i>
            <img
              v-if="myReaction"
              class="emoji"
              :src="'./assets/emojis/' + myReaction + '.png'"
              :alt="myReaction"
            />
            {{ myReaction || 'React' }}
          </span>
          <span class="mx-2"> | </span>
          <i class="cs-icons-reply-filled post-comment-action-icon"></i>
          <span
            class="cs-textstyle-paragraph-small-bold cursor-pointer"
            @click="toggleReply()"
            >Reply</span
          >
          <span class="spacer"></span>
          <span
            >{{ comment.reactionCount }}
            {{ comment.reactionCount === 1 ? 'reaction' : 'reactions' }}</span
          >
          <span v-if="comment.postLevel < 3">
            | {{ comment.comments.length }}
            {{ comment.comments.length === 1 ? 'reply' : 'replies' }}</span
          >
        </div>
      </div>
    </div>
    <div class="post-comment-replies">
      <app-post-comment
        v-for="commentData in comments"
        :key="commentData.id"
        :comment="commentData"
        :parent="comment"
        :post-id="postId"
        @refresh="emitRefresh"
      />
      <app-post-comment-composer
        v-if="showReply"
        :parent="replyParent"
        @reply="showReply = false"
        @detectLink="detectLink"
        @refresh="emitRefresh"
      />
    </div>
    <app-post-reaction
      :event="emojiClickEvent"
      :show="showReactions"
      :post="comment"
      :target-element-id="comment.id + '.reactbutton'"
      @refresh="emitRefresh"
      @close="showReactions = false"
    />

    <app-action-sheet :open="showMenu" @close="showMenu = false">
      <app-action-sheet-button v-if="isAuthor" @click="edit()"
        >Edit Comment</app-action-sheet-button
      >
      <app-action-sheet-button v-if="isAuthor" @click="deleteComment(comment)"
        >Delete Comment</app-action-sheet-button
      >
      <app-action-sheet-button
        v-if="!isAuthor"
        @click="report('comment', comment)"
        >Report Comment</app-action-sheet-button
      >
      <app-action-sheet-button
        v-if="!isAuthor"
        @click="report('user', comment.user)"
        >Report <app-username :user="comment.user"
      /></app-action-sheet-button>
      <app-action-sheet-button v-if="!isAuthor" @click="blockAuthor()"
        >Block <app-username :user="comment.user"
      /></app-action-sheet-button>
      <app-action-sheet-button
        v-if="isSuperAdmin"
        @click="deleteComment(comment)"
        >[SA] Delete Comment</app-action-sheet-button
      >
    </app-action-sheet>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import $postsApi from '@/api/posts';
import $popups from '@/services/popups';
import $reactions from '@/assets/emojis/reactions.json';

import AppAvatar from '@/components/general/Avatar.vue';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
import AppPostMedia from '@/components/post/items/PostMedia.vue';
import AppPostReaction from '@/components/post/response/PostReaction.vue';
import AppUsername from '@/components/general/Username.vue';
import AppPostCommentComposer from '@/components/post/composer/PostCommentComposer.vue';
import AppSpinner from '@/components/general/Spinner.vue';
// GQL
import DeletePost from '@/gql/posts/DeletePost.gql';
import GetTranslatedPost from '@/gql/posts/GetTranslatedPost.gql';

export default {
  name: 'AppPostComment',
  components: {
    AppAvatar,
    AppPostCommentComposer,
    AppPostReaction,
    AppPostMedia,
    AppActionSheet,
    AppActionSheetButton,
    AppUsername,
    AppSpinner,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: false,
      default: null,
    },
    postId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showMenu: false,
      showReply: false,
      showReactions: false,
      emojiClickEvent: null,
      translatedComment: null,
      loadingTranslation: false,
      isShowingTranslation: false,
      disableTranslateButton: false,
    };
  },

  computed: {
    isTranslatingComment() {
      return !this.isShowingTranslation && !this.disableTranslateButton;
    },
    isHidingTranslateComment() {
      return this.isShowingTranslation && !this.disableTranslateButton;
    },
    displayBody() {
      const finalArray = [];
      const commentLines = this.comment.bodyText.split(/\n/g);
      commentLines.forEach((line) => {
        const bodyArray = line.split(' ');
        bodyArray.forEach((data) => {
          if (data.startsWith('@')) {
            let matched = false;
            this.comment.mentions.forEach((mention) => {
              if (mention.username === data) {
                finalArray.push({
                  type: 'mention',
                  word: data,
                });
                matched = true;
              }
            });
            if (!matched) {
              finalArray.push({
                type: 'text',
                word: data,
              });
            }
          } else {
            finalArray.push({
              type: 'text',
              word: data,
            });
          }
        });
        finalArray.push({
          type: 'text',
          word: '\n',
        });
      });
      this.checkTranslationDemand(finalArray);
      return finalArray;
    },
    displayTranslatedBody() {
      const finalArray = [];
      if (this.translatedComment) {
        const commentLines =
          this.translatedComment.translated.result.split(/\n/g);
        commentLines.forEach((line) => {
          const bodyArray = line.split(' ');
          bodyArray.forEach((data) => {
            if (data.startsWith('@')) {
              let matched = false;
              this.comment.mentions.forEach((mention) => {
                if (mention.username === data) {
                  finalArray.push({
                    type: 'mention',
                    word: data,
                  });
                  matched = true;
                }
              });
              if (!matched) {
                finalArray.push({
                  type: 'text',
                  word: data,
                });
              }
            } else {
              finalArray.push({
                type: 'text',
                word: data,
              });
            }
          });
          finalArray.push({
            type: 'text',
            word: '\n',
          });
        });
      }
      return finalArray;
    },
    comments() {
      return this.comment.comments
        ? this.comment.comments.slice(0).reverse()
        : 0;
    },
    userDisplayName() {
      if (this.comment.user.displayname) return this.comment.user.displayname;
      if (this.comment.user.username)
        return this.comment.user.username.substring(1);
      return 'CloutHub User';
    },
    isAuthor() {
      return this.comment.user.id === $auth.getUserId();
    },
    replyParent() {
      if (this.comment.postLevel < 3) return this.comment;

      if (this.parent) return this.parent;
      return {
        id: this.comment.parentPostId,
        postLevel: 2,
        rootPostId: this.comment.rootPostId,
      };
    },
    myReaction() {
      if (this.comment.myReaction) {
        const myReactionType = this.comment.myReaction.type;
        const myReaction = $reactions.find(
          (reaction) => reaction.value === myReactionType
        );
        return myReaction.value;
      }
      return false;
    },
    isSuperAdmin() {
      return $auth.isAdmin();
    },
    showComment() {
      return (
        !(
          this.comment.user &&
          this.comment.user.myRelationship &&
          this.comment.user.myRelationship.isBlocked
        ) && this.comment.safe
      );
    },
  },
  methods: {
    checkTranslationDemand(finalArray) {
      finalArray.pop();
      this.disableTranslateButton = finalArray.reduce(
        (accumulator, postElement) => accumulator && postElement.word === '',
        true
      );
    },
    async emitRefresh() {
      this.$emit('refresh');
    },
    redirectToPostDetailedPage() {
      this.$router.push({
        name: 'PostDetail',
        params: { id: this.comment.rootPostId },
      });
    },
    async reactionClickHandle($event) {
      if (this.comment.myReaction) {
        await $postsApi.unreactPost(this.comment);
      } else {
        this.emojiClickEvent = $event;
        this.showReactions = !this.showReactions;
      }
    },
    async showTranslatedComment() {
      this.isShowingTranslation = true;
      this.loadingTranslation = true;
      try {
        const translatedObject = await this.$apollo.query({
          query: GetTranslatedPost,
          variables: {
            id: this.comment.id,
            languageCode: localStorage.getItem('currentLanguageCode'),
          },
          fetchPolicy: 'network-only',
        });
        this.translatedComment = translatedObject.data.post;
        this.loadingTranslation = false;
      } catch (error) {
        console.log('Error when translating comment:', error);
        this.isShowingTranslation = false;
        this.loadingTranslation = false;
      }
    },
    hideTranslatedComment() {
      this.isShowingTranslation = false;
      this.loadingTranslation = false;
    },
    edit() {
      $bus.$emit('show-edit-comment-modal', this.comment);
    },
    report(reportType, report) {
      const data = {
        reportType,
        report,
      };
      $bus.$emit('show-report-action', data);
    },
    toggleReply() {
      this.showReply = !this.showReply;
    },
    getDeletedCommentsCount(comment) {
      if (!comment.comments) return 1;
      return comment.comments.reduce(
        (count, currentComment) =>
          currentComment.comments && currentComment.comments.length > 0
            ? count + this.getDeletedCommentsCount(currentComment.comments)
            : count,
        comment.comments.length + 1
      );
    },
    async deleteComment(comment) {
      const confirmDelete = await $popups.confirmDelete({
        title: 'Delete Comment',
        message: 'Are you sure you wish to delete this comment?',
      });
      if (confirmDelete) {
        await this.$apollo.mutate({
          mutation: DeletePost,
          variables: {
            postId: comment.id,
          },
          optimisticResponse: {},
          update: (cache) => {
            const normalizedId = cache.identify(this.comment);
            cache.evict({ id: normalizedId });
            cache.gc();
            const deletedCommentsCount = this.getDeletedCommentsCount(
              this.comment
            );
            cache.modify({
              id: cache.identify({
                __typename: 'Post',
                id: this.comment.rootPostId,
              }),
              fields: {
                commentsCount(prevCount) {
                  return prevCount - deletedCommentsCount;
                },
              },
            });
          },
        });
      }
    },
    async detectLink(link) {
      this.comment.linkPreview = {
        ...link,
      };
    },
    blockAuthor() {
      $bus.$emit('show-block-user', this.comment.user);
    },
  },
};
</script>

<style scoped>
.post-comment {
  margin-bottom: 10px;
}
.post-comment-primary {
  display: flex;
  flex-direction: row;
}
.post-comment-avatar {
  margin-right: 8px; /* CloutHub requested */
}
.post-comment-body {
  flex: 1;
  width: 0;
}
.post-comment-body-message {
  background-color: var(--cs-gray-01);
  padding: 5px; /* CloutHub requested */
  border-radius: 5px;
  margin-bottom: 5px;
  word-wrap: break-word;
}
.post-comment-body-text {
  line-height: 1.3 !important; /* CloutHub requested */
}
.post-comment-body-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.post-comment-replies {
  padding-left: 20px;
  margin-top: 10px;
  border-left: solid 3px var(--cs-gray-01);
}
.post-comment-action-icon {
  margin-right: 2px;
}
.comment-response-bar {
  display: flex;
  margin-top: 5px;
  margin-bottom: 0;
}
.comment-response-bar span {
  margin-right: 5px;
}
.spacer {
  flex: 1;
}
.emoji {
  width: 13px;
  height: 13px;
}

.translate-button {
  font-weight: normal !important;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 5px 0 !important;
}

.message-margin {
  margin: 5px 0px 5px 10px;
}

.ch-translation-wrapper {
  display: flex;
  padding: 5px;
}

.ch-spinner {
  margin: 0;
}
</style>
