<template>
  <div v-if="user">
    <div class="new-post" @click="openCreatePostModal">
      <app-avatar
        v-if="!video"
        :picture="user.profilePhotoURL"
        :name="user.firstname + ' ' + user.lastname"
        size="small"
      />
      <div class="new-post__content">Share something...</div>
      <i class="cs-icons-send-filled"></i>
    </div>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import AppAvatar from '@/components/general/Avatar.vue';
import GetProfile from '@/gql/general/GetProfile.gql';

export default {
  components: {
    AppAvatar,
  },
  props: {
    groupId: {
      type: String,
      required: false,
      default: '',
    },
    video: File,
    room: {
      type: String,
      required: false,
      default: '',
    },
    link: {
      type: String,
      required: false,
      default: '',
    },
    sharedText: {
      type: String,
      required: false,
      default: '',
    },
    hasShare: {
      type: Boolean,
      default: false,
    },
    forGroups: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      videoAttachment: null,
      user: null,
    };
  },
  apollo: {
    user: {
      query: GetProfile,
      variables() {
        return {
          id: $auth.getUserId(),
        };
      },
    },
  },
  watch: {
    hasShare() {
      if (this.hasShare) {
        this.openCreatePostModal();
      }
    },
  },
  async mounted() {
    this.videoAttachment = this.video;
    if (this.video) this.showCreateModal = true;
  },

  methods: {
    // Create Modal
    openCreatePostModal() {
      const data = {
        room: this.selectedKey,
        video: this.videoAttachment,
        groupId: this.groupId,
        link: this.link,
        bodyText: this.sharedText,
        forGroups: this.forGroups,
        saveCallback: () => {
          this.$emit('save');
        },
      };
      $bus.$emit('show-post-composer-modal', data);
    },
  },
};
</script>

<style scoped>
.new-post {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  background: white;
  padding: 10px;
  border: 1px solid #e7e9f1;
  border-bottom: 1px solid var(--cs-gray-02);
  border-top: 1px solid var(--cs-gray-02);
  cursor: pointer;
}
.new-post__content {
  margin: auto 10px;
  color: var(--cs-gray-03);
  flex: 1;
}
.new-post i {
  margin: auto 0px auto auto;
  color: var(--cs-primary-base);
  font-size: 28px;
}
</style>
