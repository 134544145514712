<template>
  <div>
    <div class="user-card">
      <app-avatar
        class="ch-avatar cursor-pointer"
        :picture="user.profilePhotoURL"
        :name="user.displayname"
        variant="primary"
        size="medium"
        @click="profileCardSheet"
      ></app-avatar>
      <div class="user-card-details">
        <div
          class="cs-textstyle-paragraph-small-bold cursor-pointer"
          @click="profileCardSheet"
        >
          <app-username :user="user" />
        </div>
        <div class="cs-textstyle-paragraph-small user-card-bio">
          <span v-if="user.bio">{{ user.bio }}</span>
          <span v-else class="missing-bio">No bio</span>
        </div>
      </div>
      <div class="user-response-buttons">
        <app-connection-friend-button
          v-if="isFriend"
          :target="user"
          variant="secondary"
          fill="outline"
          size="small"
          >Friend {{ user.username }}</app-connection-friend-button
        >
        <app-connection-follow-button
          v-else
          :target="user"
          size="small"
          @changed="statusChanged"
          >Follow</app-connection-follow-button
        >
      </div>
    </div>
    <!-- action sheet -->
    <app-action-sheet
      :open="openProfileCardAction"
      @close="closeProfileCardCancelAction()"
    >
      <div class="ch-share__actionsheet">
        <div class="ch-share-modal__header">
          <i
            class="cs-icons-close align_close"
            @click="closeProfileCardCancelAction()"
          />
          <div class="user-card alignment_card">
            <div class="">
              <app-avatar
                v-router-link="{ name: 'Profile', params: user }"
                class="ch-avatar cursor-pointer"
                :picture="user.profilePhotoURL"
                :name="user.firstname"
                variant="primary"
                size="medium"
              ></app-avatar>
            </div>
            <div>
              <div class="cs-textstyle-paragraph-bold">
                {{ user.firstname ? user.firstname : user.displayname }}
              </div>
              <div>
                <app-username :user="user" class="text-color" />
              </div>
            </div>
          </div>
          <div
            v-if="user.bio"
            class="cs-textstyle-paragraph-small user-card-bio text-bio"
          >
            {{ user.bio }}
          </div>
          <div class="alignment_btn">
            <div class="follow-button">
              <cs-button
                v-router-link="{ name: 'Profile', params: user }"
                variant="secondary"
                fill="outline"
                size="small"
                >VIEW PROFILE</cs-button
              >
            </div>
            <div class="follow-button">
              <app-connection-follow-button
                :target="user"
                variant="secondary"
                fill="outline"
                size="small"
                >Follow</app-connection-follow-button
              >
            </div>
          </div>
        </div>
        <div class="ch-share-modal__body"></div>
      </div>
    </app-action-sheet>
  </div>
</template>

<script>
import AppAvatar from '@/components/general/Avatar.vue';
// import AppFollowButton from '@/components/general/FollowButton.vue';
import AppConnectionFollowButton from '@/components/general/ConnectionFollowButton.vue';
import AppConnectionFriendButton from '@/components/general/ConnectionFriendButton.vue';
import AppUsername from '@/components/general/Username.vue';
import AppActionSheet from '@/components/general/ActionSheet.vue';

export default {
  components: {
    AppAvatar,
    // AppFollowButton,
    AppConnectionFollowButton,
    AppUsername,
    AppActionSheet,
    AppConnectionFriendButton,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    isFriend: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      openProfileCardAction: false,
    };
  },
  methods: {
    profileCardSheet() {
      this.openProfileCardAction = true;
    },
    closeProfileCardCancelAction() {
      this.openProfileCardAction = false;
    },
    statusChanged() {
      this.$emit('status-changed');
    },
  },
};
</script>

<style scoped>
.text-color-mutual-friends {
  font-size: 12px;
  color: var(--cs-gray-04);
}
.text-color {
  color: var(--cs-gray-05);
}
.alignment_card {
  display: flex;
  gap: 10px;
  height: auto;
  background-color: white;
  padding: 0px !important;
  border-radius: 10px;
}
.follow-button .cs-button {
  min-width: 100px;
  color: var(--cs-gray-05);
}
.align_close {
  float: right;
}
.alignment_btn {
  display: flex;
  justify-content: space-around;
}
.user-card {
  padding: 10px;
  margin-top: 5px;
  display: flex;
  gap: 10px;
  height: auto;
  background-color: white;
}
.user-card-details {
  display: flex;
  flex-direction: column;
  gap: 0px;
  flex: 1;
  overflow: hidden;
}
.user-card-bio {
  color: var(--cs-gray-05);
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 17px;
}
.text-bio {
  margin-bottom: 10px;
}
.user-response-buttons {
  margin: auto;
}
.card-username {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  white-space: -webkit-pre-wrap;
  word-break: break-all;
  white-space: normal;
}
.firstname {
  display: inline-flex;
  align-items: center;
}
.missing-bio {
  color: var(--cs-gray-04);
}
</style>
