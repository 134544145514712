<template>
  <ion-page class="ch-home">
    <ion-header>
      <app-main-header title="Community" :message="true" />
      <app-tabs v-model="selectedTab" :tabs="tabs" />
    </ion-header>
    <ion-content>
      <!-- <app-search-bar
        placeholder="Search Posts"
        v-router-link="{ name: 'ForumSearch'}"
      /> -->
      <app-pull-to-refresh @refresh="refresh" />
      <app-post-prompt
        :has-share="hasShare"
        :link="link"
        :shared-text="text"
        @save="showPinnedPost"
      />
      <!-- <div v-if="showFeedbackLink">
        <cs-alert
          title="Welcome to the all-new CloutHub!"
          can-close
          @close="hideFeedback()"
        >
          <div>You're one of the very first people to try the new version of CloutHub! If you have any suggestions, or encounter any bugs, please <a
              style="font-size:inherit;" @click="openFeedback()">let us know.</a>
          </div>
        </cs-alert>
      </div> -->
      <app-following-post-list
        v-show="selectedTab == 0"
        ref="following-post-list"
      />
      <app-everyone-post-list
        v-show="selectedTab == 1"
        ref="everyone-post-list"
        :pin-post="pinPost"
        @removePin="removePinnedPost"
      />
    </ion-content>
    <app-create-button @click="createPost()" />
  </ion-page>
  <center><iframe id='a7e880fb2' name='a7e880fb2' src='https://ads.clouthub.com/www/delivery/afr.php?zoneid=65&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameborder='0' scrolling='no' width='1105' height='154' allow='autoplay'><a href='https://ads.clouthub.com/www/delivery/ck.php?n=a543b6f2&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://ads.clouthub.com/www/delivery/avw.php?zoneid=65&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a543b6f2' border='0' alt='' /></a></iframe></center>
</template>

<script>
import $browser from '@/services/browser';
import $bus from '@/services/bus';
import $auth from '@/services/auth';

import AppMainHeader from '@/components/general/MainHeader.vue';
// import AppSearchBar from '@/components/search/SearchBar.vue';
import AppTabs from '@/components/general/Tabs.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppPostPrompt from '@/components/post/PostPrompt.vue';
import AppEveryonePostList from '@/components/post/lists/EveryonePostList.vue';
import AppFollowingPostList from '@/components/post/lists/FollowingPostList.vue';
import AppCreateButton from '@/components/general/CreateButton.vue';

export default {
  components: {
    AppMainHeader,
    AppTabs,
    AppPullToRefresh,
    AppEveryonePostList,
    AppFollowingPostList,
    AppPostPrompt,
    AppCreateButton,
  },
  data() {
    return {
      selectedTab: (localStorage.forumSelectedTab || 0) * 1,
      tabs: [
        {
          label: 'Following',
          key: 'following',
        },
        {
          label: 'Everyone',
          key: 'everyone',
        },
      ],
      feedbackLink: null,
      pinPost: false,
      hasShare: false,
      link: null,
      text: null,
    };
  },
  computed: {
    showFeedbackLink() {
      return !localStorage.hide_feedback && this.feedbackLink;
    },
  },
  watch: {
    selectedTab() {
      localStorage.forumSelectedTab = this.selectedTab;
      this.removePinnedPost();
    },
  },
  async mounted() {
    const user = await $auth.getUser();
    this.feedbackLink = `https://app.clouthub.com/feedback.html?uid=${user.id}&email=${user.email}&device=mobileweb`;
    if (this.$route.query.url || this.$route.query.title) {
      this.hasShare = true;
      this.link = this.$route.query.url;
      this.text = this.$route.query.title;
    }

    $bus.$on('remove-share', () => {
      if (this.hasShare) {
        this.$router.push({ name: 'Home' });
        this.hasShare = false;
      }
    });
  },
  methods: {
    createPost() {
      $bus.$emit('show-post-composer-modal', {
        room: 'main',
      });
    },
    async refresh($event) {
      await Promise.all([
        this.$refs['everyone-post-list'].refresh(),
        this.$refs['following-post-list'].refresh(),
      ]);
      $event.target.complete();
    },
    hideFeedback() {
      this.feedbackLink = null;
      localStorage.hide_feedback = '1';
    },
    openFeedback() {
      $browser.open(this.feedbackLink);
    },
    removePinnedPost() {
      this.pinPost = false;
    },
    showPinnedPost() {
      this.pinPost = true;
    },
  },
};
</script>
