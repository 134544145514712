import { render, staticRenderFns } from "./PublicUsername.vue?vue&type=template&id=839d2fa2&scoped=true"
import script from "./PublicUsername.vue?vue&type=script&lang=js"
export * from "./PublicUsername.vue?vue&type=script&lang=js"
import style0 from "./PublicUsername.vue?vue&type=style&index=0&id=839d2fa2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "839d2fa2",
  null
  
)

export default component.exports