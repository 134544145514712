<template>
  <ion-page>
    <ion-content>
      <div class="final-form">
        <div class="final-form__img">
          <img src="@/assets/logo/clouthub-logo.png" alt="" />
        </div>
        <app-register-subheader title="Create your Account" :description="''" :checkspam="true" />
        <div class="final-form__info cs-textstyle-paragraph">
          <div><b>We've send a verification Email to {{ registeredEmail }}.</b></div>
          <div><b>Please go to your inbox and click the link to verify your Account.</b></div>
        </div>

        <h5 class="pt-7 text-black">Didn't receive an email?</h5>

        <div class="">
          <div class="text-gray-600 py-2">
            <span> 1)</span> <span>Confirm your email address is correct</span>
          </div>
          <div class="text-gray-600 py-2">
            <span> 2)</span> <span>Check your spam/junk folder</span>
          </div>
          <div class="text-gray-600 py-2">
            <span> 3)</span> <span class="resend-notification" @click="sendAgain()">Resend
              Notification</span>
          </div>
        </div>
      </div>
      <!-- <cs-button
          size="small"
          fill="clear"
          class="cs-button-email ch-hidden"
          @click="openUpdateEmail"
          >Change email ?</cs-button
        >
        <div :class="['otp', { 'is-disabled': !otpSent }]">
          <VueOtpInput
            ref="otpInput"
            input-classes="otp-input"
            separator=""
            :num-inputs="6"
            :is-input-num="true"
            @on-complete="handleOnComplete"
          />
        </div>
        <div class="submit-btn ch-hidden">
          <div class="cs-textstyle-paragraph-small text-light">
            Didn't receive the email?
            <cs-button
              id="timer"
              fill="clear"
              size="small"
              :disabled="!!timerCount"
              class="cs-button-email"
              @click="sendAgain"
              >Send Again {{ timerText }}</cs-button
            >
          </div>
        </div>
        <app-update-email-modal
          :show-update-modal="showUpdateModal"
          :username="draftUser.username"
          @close-update-modal="closeUpdateModal"
        ></app-update-email-modal>
      </div> -->
    </ion-content>
  </ion-page>
</template>

<script>
import $popups from "@/services/popups";
import VueOtpInput from "@bachdgvn/vue-otp-input";
import AppUpdateEmailModal from "@/components/onboarding/UpdateEmailModal.vue";
import AppRegisterSubheader from "@/components/register/Subheader.vue";
import ResendTokenVerificationEmail from '@/gql/general/ResendTokenVerificationEmail.gql';

import Register from "@/gql/general/Register.gql";
import VerifyOTP from "@/gql/onboarding/VerifyOTP.gql";
import ResendOTP from "@/gql/onboarding/ResendOTP.gql";

export default {
  components: {
    VueOtpInput,
    AppUpdateEmailModal,
    AppRegisterSubheader,
  },
  props: {
    draftUser: {
      type: Object,
      required: true,
    }, token: {
      type: String,
      required: true,
    },
    isMeeting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      otp: null,
      email: null,
      timerCount: 60,
      otpSent: true,
      registerResponse: null,
      showUpdateModal: false,
      updatedEmail: null,
    };
  },
  computed: {
   /*  timerText() {
      return this.timerCount ? `(${this.timerCount}s)` : '';
    }, */
    registeredEmail() {
      const email = this.updatedEmail
        ? this.updatedEmail
        : this.draftUser.email;

      return email;
    },
  },
  /* watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount -= 1;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  }, */
  async mounted() {
     
    this.registerResponse = await this.$apollo.mutate({
      mutation: Register,
      variables: this.draftUser,
    }); 

    console.log(this.registerResponse);
  },
  methods: {
    async sendAgain() {

      try {
        this.registerResponse = await this.$apollo.mutate({
          mutation: ResendTokenVerificationEmail,
          variables: {
            email: this.draftUser.email,
          },

        });
        this.$toast.open({
          message: 'Notification Email Sent!',
          type: 'success',
          duration: 1000,
          dismissible: true,
          position: 'top',
        });
      }
      catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.final-form {
  padding: 20px;
  background: var(--cs-gray-01);
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.final-form__img {
  width: 150px;
  margin: 50px auto 0;
}
.text-light {
  color: var(--cs-gray-03);
}
.otp {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  align-items: center;
  margin-top: 20px;
}
* >>> .otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 5px;
  border-radius: 4px;
  border: 1px solid var(--cs-gray-07);
  text-align: center;
}
* >>> .otp-input:focus {
  border: 2px solid var(--cs-primary-base) !important;
}
.error {
  border: 1px solid var(--cs-danger-base) !important;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.text-light {
  color: var(--cs-gray-03);
}
.cs-button-email {
  --cs-button-padding: 0px !important;
}

.is-disabled {
  pointer-events: none;
}
.ch-hidden {
  display: none;
}

.resend-notification {
  color: var(--cs-primary-base);
  cursor: pointer;
  text-decoration: underline;
}
</style>
