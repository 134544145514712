import { render, staticRenderFns } from "./ChangeUsernameModal.vue?vue&type=template&id=f5fe1656&scoped=true&lang=html"
import script from "./ChangeUsernameModal.vue?vue&type=script&lang=js"
export * from "./ChangeUsernameModal.vue?vue&type=script&lang=js"
import style0 from "./ChangeUsernameModal.vue?vue&type=style&index=0&id=f5fe1656&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5fe1656",
  null
  
)

export default component.exports