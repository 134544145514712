<template>
  <div id="app">
    <ion-app id="main">
      <ion-vue-router
        id="main-contentz"
        :key="$route.fullPath"
        name="default"
      ></ion-vue-router>
    </ion-app>
    <app-popup-manager />
    <app-popup-notification v-if="isLoggedIn" />
    <app-feedback />
    <portal-target name="action-sheets" />
    <portal-target name="modals" multiple />
  </div>
</template>

<script>
import $browser from '@/services/browser';

import AppPopupManager from '@/components/popupManager/Index.vue';
import AppPopupNotification from '@/components/popupNotification/Index.vue';
import AppFeedback from '@/components/general/Feedback.vue';

import GetShortlinkInfo from '@/gql/general/GetShortlinkInfo.gql';
import $auth from '@/services/auth';
import $wwtc from '@/services/wwtc';

export default {
  name: 'App',
  components: {
    AppPopupManager,
    AppPopupNotification,
    AppFeedback,
  },
  async mounted() {
    const me = await $auth.getUser();
    if (me.language) {
      if (!localStorage.getItem('currentLanguageCode')) {
        localStorage.setItem('currentLanguageCode', me.language);
      } else if (localStorage.getItem('currentLanguageCode') !== me.language) {
        localStorage.setItem('currentLanguageCode', me.language);
        this.availableLanguages =
          await $wwtc.getAvailableForTranslateLanguages();
        this.viewMore = false;
        const languageName = this.availableLanguages.find(
          (el) => el.code === me.language
        ).name;
        localStorage.setItem('currentLanguage', languageName);
      }
    } else {
      localStorage.setItem('currentLanguage', 'English (United States)');
      localStorage.setItem('currentLanguageCode', 'english-united-states');
    }
  },
  computed: {
    isLoggedIn() {
      return $auth.isLoggedIn();
    },
  },
  created() {
    document.addEventListener('deviceready', async () => {
      try {
        if (window.universalLinks) {
          window.universalLinks.subscribe(null, async (eventData) => {
            const pathMap = {
              GROUP: 'GroupDetail',
              CHANNEL: 'VideoProfile',
              USER: 'Profile',
              VIDEO: 'VideoDetail',
              POST: 'PostDetail',
              MEETING: 'MeetingDetail',
            };
            const url = eventData.url
              .replace('https://clouthub.com', '')
              .replace('http://clouthub.com', '');
            let type = 'USER';
            let identifier = url.replace('/', '');
            if (!identifier.startsWith('@')) {
              identifier = '@' + identifier;
            }
            if (url.startsWith('/m/')) {
              type = 'MEETING';
              identifier = url.replace('/m/', '');
            } else if (url.startsWith('/g/')) {
              type = 'GROUP';
              identifier = url.replace('/g/', '');
            } else if (url.startsWith('/c/')) {
              type = 'CHANNEL';
              identifier = url.replace('/c/', '');
            } else if (url.startsWith('/p/')) {
              type = 'POST';
              identifier = url.replace('/p/', '');
            } else if (url.startsWith('/v/')) {
              type = 'VIDEO';
              identifier = url.replace('/v/', '');
            }
            try {
              const resp = await this.$apollo.query({
                query: GetShortlinkInfo,
                variables: { type, identifier },
              });
              const id = resp.data.id;
              const path = pathMap[type];
              if (type === 'MEETING') {
                this.openMeeting(id);
              } else {
                this.$router.push({ name: path, params: { id } });
              }
            } catch (err) {
              alert(`Error: ${err.message}`);
              alert(url);
              alert(type);
            }
          });
        }
      } catch (err) {}
    });
  },
  methods: {
    openMeeting(meetingId) {
      let domain = 'https://m.clouthub.com';
      const accessToken = encodeURIComponent(localStorage.access_token);
      const next = encodeURIComponent(`/meetings/${meetingId}`);
      const url = `${domain}/go.html?access_token=${accessToken}&next=${next}`;
      $browser.open(url);
    },
  },
};
</script>

<style></style>
