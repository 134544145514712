import { render, staticRenderFns } from "./PostHeader.vue?vue&type=template&id=17c56b3b&scoped=true"
import script from "./PostHeader.vue?vue&type=script&lang=js"
export * from "./PostHeader.vue?vue&type=script&lang=js"
import style0 from "./PostHeader.vue?vue&type=style&index=0&id=17c56b3b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17c56b3b",
  null
  
)

export default component.exports