var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ch-profile-tab-posts"},[_c('div',{staticClass:"ch-mutual ch-connection-box"},[_c('div',{staticClass:"title cs-textstyle-paragraph-bold"},[_vm._v("Mutual Interests")]),_c('div',{staticClass:"ch-mutual-list-box"},[_c('div',{staticClass:"interest"},[_vm._v(" Local Interests: "),(
            !_vm.user.mutual_local_interests ||
            !_vm.user.mutual_local_interests.length
          )?_c('span',{staticClass:"cs-gray-04"},[_vm._v(" Nothing in common ")]):_vm._e(),_vm._l((_vm.user.mutual_local_interests),function(interest,$index){return _c('cs-tag',{key:interest + $index},[_c('span',[_vm._v(_vm._s(interest))])])})],2),_c('div',{staticClass:"interest"},[_vm._v(" National Interests: "),(
            !_vm.user.mutual_national_interests ||
            !_vm.user.mutual_national_interests.length
          )?_c('span',{staticClass:"cs-gray-04"},[_vm._v(" Nothing in common ")]):_vm._e(),_vm._l((_vm.user.mutual_national_interests),function(interest,$index){return _c('cs-tag',{key:interest + $index},[_c('span',[_vm._v(_vm._s(interest))])])})],2)])]),_c('div',{staticClass:"ch-mutual ch-connection-box"},[_c('div',{staticClass:"title cs-textstyle-paragraph-bold"},[_vm._v("You both follow")]),_c('div',{staticClass:"ch-mutual-list-box"},[_c('app-user-list',{attrs:{"users":_vm.user.mutual_followers}})],1)]),_c('div',{staticClass:"ch-mutual"},[_c('div',{staticClass:"title cs-textstyle-paragraph-bold"},[_vm._v("Follow you both")]),_c('div',{staticClass:"ch-mutual-list-box"},[_c('app-user-list',{attrs:{"users":_vm.user.mutual_following}})],1)]),_c('div',{staticClass:"ch-mutual ch-connection-box"},[_c('div',{staticClass:"title cs-textstyle-paragraph-bold"},[_vm._v("Mutual Friends")]),_c('div',{staticClass:"ch-mutual-list-box"},[_c('app-user-list',{attrs:{"users":_vm.user.mutual_friends}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }