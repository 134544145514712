<template>
  <div>
    <!-- Only appears in detail views -->
    <div v-if="isDetailView">
      <div v-if="comments">
        <post-comment
          v-for="comment in displayComments"
          :id="'comment.' + comment.id"
          :key="comment.id"
          :comment="comment"
          post-id="post.id"
        ></post-comment>
      </div>
      <div
        v-if="hiddenCommentCount && !this.$apollo.queries.comments.loading"
        class="load-more-comments cs-textstyle-paragraph-small-bold detail-load-more-comments"
        @click="loadMore()"
      >
        {{ hiddenCommentCount }} more comment{{
          hiddenCommentCount > 1 ? 's' : ''
        }}
      </div>
      <app-spinner v-if="$apollo.queries.comments.loading" />
    </div>

    <!-- Latest post -->
    <div v-if="latestComment">
      <post-comment
        :id="'comment.' + latestComment.id"
        :post-id="post.id"
        :comment="latestComment"
        @refresh="emitRefresh"
      ></post-comment>
    </div>
  </div>
</template>

<script>
import AppSpinner from '@/components/general/Spinner.vue';
import ListPostComments from '@/gql/posts/PublicListPostComments.gql';
import PostComment from '@/components/post/response/PublicPostComment.vue';

export default {
  components: {
    PostComment,
    AppSpinner,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mayHaveMore: true,
    };
  },
  apollo: {
    comments: {
      query: ListPostComments,
      variables() {
        return {
          postId: this.post.id,
        };
      },
      skip() {
        return !this.isDetailView;
      },
    },
  },
  computed: {
    isDetailView() {
      //return this.$route.name === 'PostDetail';
    },
    hiddenCommentCount() {
      if (!this.comments) return 0;
      let count = this.post.replyCount - this.comments.length;
      if (this.post.latestReply && count) count -= 1;
      return count;
    },
    displayComments() {
      const latestComment = this.post.comments[0];
      return (
        this.comments &&
        this.comments.filter((c) => !latestComment || c.id !== latestComment.id)
      );
    },
    latestComment() {
      return (
        this.post.latestReply || (this.post.comments && this.post.comments[0])
      );
    },
  },
  async mounted() {
    if (this.$route.query.comment) {
      while (this.mayHaveMore) {
        await this.loadMore();
      }
      setTimeout(() => {
        const key = `comment.${this.$route.query.comment}`;
        const commentElement = document.getElementById(key);
        commentElement.scrollIntoView();
      }, 1000);
    }
  },
  methods: {
    async emitRefresh() {
      this.$emit('refresh');
    },
    loadMore() {
      if (this.mayHaveMore) {
        this.mayHaveMore = false;
        let after = null;
        if (this.comments && this.comments.length) {
          after = this.comments[this.comments.length - 1].createdAt;
        }
        this.$apollo.queries.comments.fetchMore({
          variables: {
            postId: this.post.id,
            after,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            this.mayHaveMore = !!fetchMoreResult.comments.length;
            return {
              comments: [
                ...previousResult.comments,
                ...fetchMoreResult.comments,
              ],
            };
          },
        });
      }
    },
  },
};
</script>

<style>
.reverse-comments {
  display: flex;
  flex-direction: column-reverse;
}
.load-more-comments {
  cursor: pointer;
  color: var(--cs-primary-base);
}
.detail-load-more-comments {
  padding-top: 10px;
  margin-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-top: solid 1px var(--cs-gray-01);
  border-bottom: solid 1px var(--cs-gray-01);
}
.list-load-more-comments {
  text-align: left;
  padding-top: 5px; /* CloutHub requested */
  padding-bottom: 5px; /* CloutHub requested */
  margin-top: -5px; /* CloutHub requested */
}
</style>
