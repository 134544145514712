var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.groups && _vm.groups.length)?_c('div',{staticClass:"groups-row"},_vm._l((_vm.groups),function(group,index){return _c('div',{directives:[{name:"router-link",rawName:"v-router-link",value:(
        _vm.hasMoreItem(index)
          ? { name: 'MyGroupsList' }
          : { name: 'GroupDetail', params: group }
      ),expression:"\n        hasMoreItem(index)\n          ? { name: 'MyGroupsList' }\n          : { name: 'GroupDetail', params: group }\n      "}],class:{ 'group-item': true, 'group-item-more': _vm.hasMoreItem(index) }},[_c('div',{staticClass:"group-item-img",style:({
          'background-image': `url(${group.picture || _vm.defaultPicture})`,
        })}),(_vm.hasMoreItem(index))?_c('span',{staticClass:"group-item-label group-item-count"},[_vm._v("+"+_vm._s(_vm.myGroupsQty - _vm.gridSize))]):_c('span',{staticClass:"group-item-label group-item-name"},[_vm._v(_vm._s(group.name))])])}),0):_vm._e(),_c('my-groups-post-list')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }