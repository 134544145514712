<template>
  <div>
    <div class="search-bar__input-header">
      <div class="search-bar__container">
        <div class="search-bar">
          <i class="cs-icons-search" @click="$refs.nativeInput.focus()" />
          <input
            ref="nativeInput"
            v-model="query"
            type="text"
            placeholder="Enter here"
            @input="$emit('input', query)"
            @keydown.enter.native="search()"
          />
        </div>
        <div v-if="query.length" class="close-icon__container">
          <i class="cs-icons-close close-icon" @click="clean"></i>
        </div>
      </div>
      <cs-button
        variant="secondary"
        size="small"
        fill="outline"
        @click="search"
      >
        Find
      </cs-button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['input'],
  data() {
    return {
      query: '',
    };
  },
  mounted() {
    this.$emit('input', this.query);
    this.$emit('clearInput', this.clean);
  },
  methods: {
    clean() {
      this.query = '';
      this.$emit('input', this.query);
    },
    search() {
      this.$emit('search');
    },
  },
};
</script>

<style scoped>
.close-icon {
  color: var(--cs-gray-01);
  font-size: 13px;
}
.close-icon__container {
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: var(--cs-gray-03);
  border-radius: 50%;
  text-align: center;
}
.search-bar__input-header {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: solid 1px #efefef;
}
.search-bar__container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 20px;
}
.search-bar {
  display: flex;
  border: solid 1px var(--cs-gray-05);
  border-radius: 15px;
  flex: 1;
  overflow: hidden;
  align-items: center;
}
.search-bar input {
  flex: 1;
  border: none;
  padding: 5px;
}
.search-bar input:focus {
  outline-width: 0;
}

.search-bar i {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
