import { render, staticRenderFns } from "./GroupUserSelect.vue?vue&type=template&id=141a3d50&scoped=true"
import script from "./GroupUserSelect.vue?vue&type=script&lang=js"
export * from "./GroupUserSelect.vue?vue&type=script&lang=js"
import style0 from "./GroupUserSelect.vue?vue&type=style&index=0&id=141a3d50&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "141a3d50",
  null
  
)

export default component.exports