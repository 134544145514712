<template>
  <div v-if="safeToDisplay" class="ch-video-item" :class="direction">
    <div class="ch-video-item__body--meta">
      <app-avatar
        v-router-link="{ name: 'VideoProfile', params: video.user }"
        :name="video.user.displayname"
        :picture="video.user.profilePhotoURL"
        size="small"
        @click="emitLeave"
      ></app-avatar>
      <div class="video-item-header">
        <div class="video-item-header-row">
          <div
            v-router-link="{ name: 'VideoDetail', params: video }"
            class="video-item-title cs-textstyle-paragraph-bold"
            @click="emitLeave"
          >
            {{ title }}
          </div>
          <i class="ch-icons-options" @click="showMenu = true"></i>
        </div>
        <div class="video-item-header-row">
          <div class="ch-video-item__body--meta-info">
            <div
              v-router-link="{ name: 'VideoProfile', params: video.user }"
              class="cs-textstyle-paragraph-small-bold"
              @click="emitLeave"
            >
              <app-username :user="video.user" type="displayname" />
            </div>
            <div class="cs-textstyle-paragraph-small text-light">
              {{ video.viewCount || 0 }}
              {{ video.viewCount === 1 ? 'view' : 'views' }} ·
              {{ video.createdAt | moment('from') }}
            </div>
          </div>
          <div class="ch-video-item__body--meta-icons">
            <i class="ch-icons-share" @click="shareVideo()"></i>
            <app-bookmark-button size="medium" :post="video" />
            <app-queue-button size="medium" :post="video" />
            <!-- <app-download-video-button v-if="postedByMe" :video="video" /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="ch-video-item__header">
      <div v-if="isVideoLive" class="ch-live">LIVE</div>
      <div>
        <app-video-thumbnail
          class="ch-video-item__thumbnail"
          :video="video"
        ></app-video-thumbnail>
        <img 
          v-if="postedByMe && video.ispinnedonchannel"
          class="icon-action config-item-icon icon-overlay" 
          :src="pinnedVideoIcon" 
        />
      </div>
      
    </div>
    <app-action-sheet :open="showMenu" @close="closeMenu">
      <app-action-sheet-button v-if="postedByMe" @click="editVideo()"
        >Edit Video</app-action-sheet-button
      >
      <app-action-sheet-button v-if="postedByMe" @click="deleteVideo"
        >Delete Video</app-action-sheet-button
      >
      <app-action-sheet-button v-if="postedByMe" @click="downloadVideo()"
        >Download Video</app-action-sheet-button
      >
      <app-action-sheet-button v-if="!postedByMe" @click="report('video')"
        >Report Video</app-action-sheet-button
      >
      <app-action-sheet-button v-if="postedByMe" @click="report('own-video')"
        >Report Video</app-action-sheet-button
      >
      <app-action-sheet-button v-if="postedByMe && !video.ispinnedonchannel" @click="pinVideo()"
        >Pin Video</app-action-sheet-button
      >
      <app-action-sheet-button v-if="!postedByMe" @click="report('user')"
        >Report <app-username :user="video.user"
      /></app-action-sheet-button>
      <app-action-sheet-button v-if="!postedByMe" @click="blockAuthor()"
        >Block <app-username :user="video.user"
      /></app-action-sheet-button>
      <app-action-sheet-button v-if="isSuperadmin" @click="deleteVideo"
        >[SA] Delete Video</app-action-sheet-button
      >
    </app-action-sheet>
  </div>
</template>

<script>
import $bus from '@/services/bus';
import $auth from '@/services/auth';
import $popups from '@/services/popups';
import $videosApi from '@/api/videos';

// Component
import AppAvatar from '@/components/general/Avatar.vue';
import AppUsername from '@/components/general/Username.vue';
import AppVideoThumbnail from '@/components/videos/VideoThumbnail.vue';
import AppBookmarkButton from '@/components/post/buttons/BookmarkButton.vue';
import AppQueueButton from '@/components/post/buttons/QueueButton.vue';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
import pinnedVideoIcon from '@/assets/icons/pinnedvideoicon.svg';

export default {
  components: {
    AppAvatar,
    AppVideoThumbnail,
    AppUsername,
    AppQueueButton,
    AppBookmarkButton,
    AppActionSheet,
    AppActionSheetButton,
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
    direction: {
      type: String,
      required: false,
      default: 'row',
    },
  },
  data() {
    return {
      showMenu: false,
      pinnedVideoIcon
    };
  },
  computed: {
    isVideoLive() {
      return this.video && this.video.video && this.video.video.isLive;
    },
    title() {
      if (this.video) {
        // return this.video.video.title || this.video.video.name || this.video.bodyText || 'Untitled';
        return (
          (this.video.video && this.video.video.title) ||
          this.video.bodyText ||
          (this.video.video && this.video.video.name) ||
          'Untitled Video'
        );
      }
      return '';
    },
    postedByMe() {
      return (
        this.video &&
        this.video.user &&
        this.video.user.id === $auth.getUserId()
      );
    },
    isSuperadmin() {
      return $auth.isAdmin();
    },
    safeToDisplay() {
      return this.postedByMe || this.video.safe;
    },
  },
  methods: {
    emitLeave() {
      $bus.$emit('ch-tabs-leave', { route: this.$route });
    },
    shareVideo() {
      const link = `/v/${this.video.slug || this.video.id}`;
      $bus.$emit('show-share-sheet', {
        link,
        post: this.video,
        title: 'Share Video',
      });
    },
    // Report
    report(type) {
      const reportData = {
        report:
          type === 'video' || type === 'own-video'
            ? this.video
            : this.video.user,
        reportType: type,
      };
      $bus.$emit('show-report-action', reportData);
    },
    // Block
    blockAuthor() {
      $bus.$emit('show-block-user', this.video.user);
    },
    // Delete
    async deleteVideo() {
      const confirmDelete = await $popups.confirmDelete({
        title: 'Delete Video',
        message: 'Are you sure you wish to delete this video?',
      });
      if (!confirmDelete) return;
      await $videosApi.deleteVideo(this.video);
    },
    // Edit
    editVideo() {
      $bus.$emit('show-edit-video-modal', this.video);
    },
    closeMenu() {
      this.showMenu = false;
    },
    downloadVideo() {
      $videosApi.downloadVideo(this.video.video);
    },
    async pinVideo() {
      await $videosApi.pinUnpinUserVideo(this.video.id, true);
    }
  },
};
</script>

<style scoped>
.video-item-header {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}
.video-item-header-row {
  display: flex;
  flex: 1;
  gap: 10px;
}
.video-item-title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ch-video-item {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  border: solid 1px var(--cs-gray-02);
}
.ch-video-item.row {
  height: 280px;
  width: 300px;
}
.ch-video-item__thumbnail {
  width: 100%;
  height: 210px;
  object-fit: contain;
  background-color: black;
}
.ch-video-item__body--title {
  padding: 10px;
  /* width: 200px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ch-video-item__body--meta {
  display: flex;
  padding: 10px;
  gap: 10px;
  border-top: solid 1px var(--cs-gray-01);
}
.ch-video-item__body--meta-info {
  flex: 1;
}
.ch-video-item__body--meta-extras {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.ch-video-item__body--meta-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: var(--cs-gray-05);
}

.ch-video-item__body--meta-icons i {
  font-size: 16px;
}
.ch-live {
  position: absolute;
  background: red;
  color: white;
  padding: 0 10px;
}
.ch-video-item__header {
  position: relative;
}
.icon-overlay {
  width: 24px;
  transform: rotate(30deg);
  position: absolute;
  right: 16px;
  margin-top: 12px;
}
</style>
