<template>
  <div>
    <!-- Links -->
    <app-post-link-preview
      v-if="linkPreview"
      :title="linkPreview.title"
      :description="linkPreview.details"
      :picture="linkPreview.image"
      :hide-picture="!!images && !!images.length"
      :src="linkPreview.url"
      :preview="true"
    />
    <!-- Images -->
    <div v-if="images" class="scroll-container">
      <!-- <div class="scroll-button" v-if="displayMedia.images.length > 1" @click="scroll_right">
        <i class="cs-icons-next-angle cs-textstyle-paragraph-bold"></i>
      </div> -->
      <div ref="scrollContainer" class="image-wrap">
        <div
          v-for="(image, ind) in displayMedia.images"
          :key="image.src + ind"
          :class="
            displayMedia.images.length > 1
              ? 'gridscroll-multiple'
              : 'gridscroll'
          "
          @click="showImg(ind)"
        >
          <app-img class="post-media-img" :src="image.src" auto-optimize />
        </div>
      </div>
      <div v-if="displayMedia.images.length > 0">
        <app-image-preview
          :show="visible"
          :data="displayMedia.images"
          @close="handleHide"
        />
      </div>
    </div>
    <!-- Documents/Files -->
    <div class="ch-docs">
      <cs-file-preview
        v-if="document"
        :src="cdn(document.url)"
        :file-type="document.type"
        :title="document.name"
        :file-size="document.size"
        @click="openPDF(document.url)"
      ></cs-file-preview>
    </div>
    <!-- Videos -->
    <app-video
      v-if="video"
      class="ch-video"
      :src="videoSrc"
      :poster="cdn(video.thumbUrl)"
      :post-id="postId"
      :stream-id="video.streamId"
      :stream-status="video.streamStatus"
      :show-ads="false"
      :pause-if-hidden="!autoplay"
      :autoplay="autoplay"
    >
    </app-video>
  </div>
</template>

<script>
import $browser from '@/services/browser';
import $cdn from '@/services/cdn';
import AppImagePreview from '@/components/general/ImagePreview.vue';
import AppVideo from '@/components/general/Video.vue';
import AppImg from '@/components/general/Img.vue';
import AppPostLinkPreview from './PostLinkPreview.vue';

export default {
  components: {
    AppPostLinkPreview,
    AppImagePreview,
    AppVideo,
    AppImg,
  },
  props: {
    linkPreview: {
      type: Object,
      required: false,
      default: null,
    },
    images: {
      type: Array,
      required: false,
      default: () => [],
    },
    document: {
      type: Object,
      required: false,
      default: null,
    },
    video: {
      type: Object,
      required: false,
      default: null,
    },
    postId: {
      type: String,
      required: false,
      default: '',
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      index: 0,
      displayMedia: {
        images: [],
        video: null,
      },
    };
  },
  computed: {
    videoSrc() {
      if (this.video) {
        if (
          this.video.streamStatus === 'COMPLETE' ||
          this.video.streamStatus === 'PLAYABLE'
        ) {
          return `https://${process.env.VUE_APP_VIDEO_DOMAIN}/${this.video.streamId}/playlist.m3u8`;
        }
        return $cdn.optimize(this.video.url);
      }
      return null;
    },
  },
  watch: {
    images() {
      this.formatInages();
    },
  },
  async mounted() {
    this.formatInages();
  },
  methods: {
    scroll_right() {
      const content = this.$refs.scrollContainer;
      content.scrollLeft += 60;
    },
    formatInages() {
      if (this.images) {
        this.displayMedia.images = this.images.map((image) => ({
          src: this.cdn(image.url),
        }));
      }
    },
    openPDF(url) {
      $browser.open(this.cdn(url));
    },
    cdn(url) {
      return url ? $cdn.optimize(url) : '';
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.post-media-img {
  width: 100%;
}

.scroll-button i {
  color: white;
  position: relative;
  top: 50%;
  z-index: 2;
  padding: 0 20px;
  text-align: center;
}
.image-wrap {
  overflow-x: hidden;
  /* display: flex; */
}
.scroll-button {
  position: absolute;
  right: 0;
  padding: 100px 0;
  height: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.4);
}
.ch-video {
  max-height: 250px;
}
.ch-docs {
  padding: 0px 20px;
}
.ch-docs >>> .cs-file-preview__content {
  padding: 10px; /* CloutHub requested */
}

.scroll-container {
  overflow: auto;
  white-space: nowrap;
  background: transparent;
  height: 100%;
  position: relative;
}

.gridscroll {
  display: inline-block;
  width: 100%;
}

.gridscroll-multiple {
  display: inline-block;
  width: 90%;
  height: 100%;
  vertical-align: middle;
}

.gridscroll img {
  margin-right: 10px;
  min-height: 200px;
  max-height: 1200px;
  object-fit: contain;
}
</style>
