<template>
  <div v-show="show" class="hamburger-menu-backdrop" @click="close()">
    <div class="hamburger-menu">
      <div class="hamburger-menu-close-wrapper">
        <i class="cs-icons-close-filled" @click="close()" />
      </div>

      <div v-if="!!user" class="ch-menu__body-content">
        <cs-profile
          v-router-link="{ name: 'Profile', params: { id: userId } }"
          :picture="cdn(user.profilePhotoURL)"
          :name="user.displayname"
          :detail="user.username"
          avatar-size="medium"
          avatar-position="left"
          size="large"
        />
        <div class="ch-menu__buttons">
          <app-menu-nav-item
            v-router-link="{ name: 'Profile', params: { id: userId } }"
            icon="ch-icons-user"
            label="My Profile"
          />
          <app-menu-nav-item
            v-router-link="{ name: 'Messages' }"
            icon="ch-icons-message"
            label="Messages"
          />
          <app-menu-nav-item
            v-router-link="{ name: 'Notifications' }"
            icon="ch-icons-notification"
            label="Notifications"
          />
          <app-menu-nav-item
            v-router-link="{ name: 'Requests' }"
            icon="ch-icons-add-user"
            label="Requests"
          />
          <app-menu-nav-item
            v-router-link="{ name: 'BookmarkPostList' }"
            icon="cs-icons-bookmark"
            label="My Bookmarked Posts"
          />
          <!-- <app-menu-nav-item
            v-router-link="{ name: 'Friends', params: { id: userId } }"
            icon="cs-icons-members"
            label="Friends/Followers"
          /> -->
          <app-menu-nav-item
            v-router-link="{ name: 'connections' }"
            icon="ch-icons-connections"
            label="Discover People"
          />
          <app-menu-nav-item
            v-router-link="{ name: 'Settings' }"
            icon="cs-icons-settings"
            label="Settings"
          />
          <app-menu-nav-item
            icon="ch-icons-privacy"
            label="Privacy Policy"
            @click="privacy"
          />
          <app-menu-nav-item
            icon="ch-icons-secure"
            label="Terms of Use"
            @click="terms"
          />
          <!-- <app-menu-nav-item
            icon="ch-icons-help"
            label="Help"
            @click="help"
          /> -->
          <app-menu-nav-item
            icon="cs-icons-help"
            label="Help / Report a Bug"
            @click="reportBug"
          />
          <app-menu-nav-item
            icon="ch-icons-logout"
            label="Sign Out"
            @click="logOut"
          />
        </div>
      </div>
      <app-logout-user
        :show="showLogoutPopup"
        @close="showLogoutPopup = false"
      />
    </div>
  </div>
</template>
<script>
import $auth from '@/services/auth';
import $cdn from '@/services/cdn';
import $browser from '@/services/browser';
import $bus from '@/services/bus';

import GetProfile from '@/gql/general/GetProfile.gql';

import AppLogoutUser from '@/components/general/Logout.vue';
import AppMenuNavItem from '@/components/menu/MenuNavItem.vue';
import { policyPagesUrls } from '@/constants';

export default {
  components: {
    AppLogoutUser,
    AppMenuNavItem,
  },
  apollo: {
    user: {
      query: GetProfile,
      variables() {
        return {
          id: $auth.getUserId(),
        };
      },
    },
  },
  data() {
    return {
      userId: $auth.getUserId(),
      showLogoutPopup: false,
      user: null,
      show: false,
    };
  },
  async mounted() {
    $bus.$on('showAppMenu', () => {
      this.show = true;
    });
    $bus.$on('hideAppMenu', () => {
      this.show = false;
    });
  },
  methods: {
    close() {
      this.show = false;
    },
    cdn(url) {
      if (!url) return;
      // eslint-disable-next-line consistent-return
      return $cdn.optimize(url);
    },
    async logOut() {
      this.showLogoutPopup = true;
    },
    privacy() {
      $browser.open(policyPagesUrls.privacyPolicyUrl);
    },
    help() {
      $browser.open('https://www.clouthub.com/help');
    },
    terms() {
      $browser.open(policyPagesUrls.termsOfUseUrl);
    },
    async reportBug() {
      let device = 'mobile';
      if (window.cordova) {
        device = `mobile.installed.${window.cordova.platformId}`;
      }
      const feedbackLink = `https://app.clouthub.com/feedback.html?uid=${this.user.id}&email=${this.user.email}&device=${device}`;
      $browser.open(feedbackLink);
    },
  },
};
</script>
<style scoped>
.hamburger-menu-backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);
}
.hamburger-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 80%;
  z-index: 10000;
  background-color: white;
  border-right: solid 1px #ccc;
  padding: 10px;
  overflow: scroll;
}
.hamburger-menu-close-wrapper {
  text-align: right;
}
</style>
