<template>
  <ion-page>
    <ion-content>
      <div class="ch-set-password">
        <div class="ch-set-password__img-class">
          <img src="@/assets/logo/clouthub-logo.png" />
        </div>
        <app-register-subheader
          title="Create your Account"
          description="Enter your information below to create your account."
        />
        <app-register-form>
          <app-password-input v-model="password" label="Password" />
          <app-password-input
            v-model="confirmPassword"
            label="Confirm Password"
          />
          <app-password-validator
            v-model="validPassword"
            :password="password"
            :confirm-password="confirmPassword"
          />

          <vue-recaptcha
            ref="recaptcha"
            size="invisible"
            :sitekey="sitekey"
            @verify="continueToPhonePage"
            @expired="onCaptchaExpired"
            
          >
            <cs-button
              block
              corners="round"
              size="large"
              :disabled="!validPassword"
              
            >
              Continue
            </cs-button>
          </vue-recaptcha>
          <div v-if="!isMeeting" class="ch-set-password__back">
            <div class="cs-textstyle-paragraph">
              <cs-button block fill="clear" size="large" @click="previous"
                >Back</cs-button
              >
            </div>
            <div class="cs-textstyle-paragraph">
              <cs-button block fill="clear" size="large" to="/login"
                >Back to Login</cs-button
              >
            </div>
          </div>
        </app-register-form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import AppRegisterForm from '@/components/register/Form.vue';
import AppPasswordInput from '@/components/general/PasswordInput.vue';
import AppRegisterSubheader from '@/components/register/Subheader.vue';
import AppPasswordValidator from '@/components/general/PasswordValidator.vue';
import { VueRecaptcha } from 'vue-recaptcha';
import { gReCaptchaSiteKey } from '@/constants';

export default {
  components: {
    AppPasswordInput,
    AppPasswordValidator,
    AppRegisterSubheader,
    AppRegisterForm,
    VueRecaptcha,
  },
  props: {
    isMeeting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sitekey: gReCaptchaSiteKey,
      password: '',
      confirmPassword: '',
      validPassword: false,
    };
  },
  methods: {


    
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    continueToPhonePage(recaptchaToken) {
      this.$emit('next', { password: this.password, recaptchaToken });
    },
    previous() {
      this.$emit('previous');
    },
  },
};
</script>

<style scoped>
.ch-set-password {
  padding: 20px;
  background: var(--cs-gray-01);
}
.ch-set-password__img-class {
  width: 150px;
  margin: 50px auto 0px;
}
.ch-set-password__back {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px auto;
}
</style>
