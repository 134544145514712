var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ion-page',[_c('ion-header',[(_vm.group)?[(!_vm.group.parentGroupId)?_c('div',{staticClass:"group-detail-view__banner-content"},[_c('i',{staticClass:"cs-icons-back-arrow group-detail-view__back",on:{"click":function($event){return _vm.$router.go(-1)}}})]):_c('app-category-header',{attrs:{"title":_vm.subgroupTitle}})]:_vm._e()],2),_c('ion-content',[_c('app-pull-to-refresh',{on:{"refresh":_vm.refresh}}),(!_vm.group)?_c('cs-spinner'):_c('div',[_c('div',{staticClass:"group-detail-view__maincard"},[_c('app-group-banner-image',{attrs:{"group":_vm.group}}),_c('div',{staticClass:"group-detail-view__maincard-padding"},[_c('div',{staticClass:"group-detail-view__title-bar"},[_c('div',{staticClass:"group-detail-view__title"},[(_vm.group.parentGroupId)?_c('div',{staticClass:"cs-textstyle-paragraph-small-bold group-detail-view__parent-group-title",on:{"click":function($event){return _vm.$router.push({
                    name: 'GroupDetail',
                    params: { id: _vm.group.parentGroupId },
                  })}}},[_c('i',{staticClass:"ch-icons-groups"}),_vm._v(" "+_vm._s(_vm.parentGroup.name)+" ")]):_vm._e(),_c('div',{staticClass:"cs-textstyle-section-heading"},[_vm._v(_vm._s(_vm.group.name))]),_c('div',{staticClass:"group-detail-view__member-count cs-textstyle-paragraph",on:{"click":_vm.viewGroupMembers}},[_vm._v(" "+_vm._s(_vm.group.memberCount)+" "+_vm._s(_vm.membersLabel)+" ")])]),(_vm.userIsModerator)?_c('app-group-send-notification-button',{staticClass:"group-detail-view__share",on:{"click":function($event){_vm.showNotificationModal = true}}}):_vm._e(),_c('app-group-menu',{attrs:{"group":_vm.group}})],1),(_vm.group.description)?_c('div',{staticClass:"group-detail-view__description"},[_c('app-expandable-text',{staticClass:"cs-textstyle-paragraph-small group-detail-view__description",attrs:{"content":_vm.groupDescription,"placeholder":"No description","max-lines":7}})],1):_vm._e(),(_vm.userIsMember || _vm.group.privacy === 'public')?_c('app-group-members-avatar-list',{attrs:{"group":_vm.group},on:{"click":_vm.viewGroupMembers}}):_vm._e(),_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"flex-start"}},[(_vm.userIsMember)?_c('app-group-share-button',{staticClass:"group-detail-view__share",attrs:{"group":_vm.group}}):_vm._e(),_c('app-group-join-button',{attrs:{"group":_vm.group}}),_c('div',{staticStyle:{"flex":"1 1"}}),(_vm.group.id === '6196dace-09d1-5cb5-a73d-ebff708c1df1')?_c('cs-button',{staticClass:"group-detail-view__action_button",attrs:{"size":"small","variant":"danger","href":"https://www.supportcleanelections.com","target":"_blank"}},[_vm._v(" Report Fraud ")]):_vm._e()],1)],1)],1),_c('app-tabs',{attrs:{"tabs":_vm.tabs},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}}),(
          !_vm.userIsMember &&
          !_vm.userIsSuperAdmin &&
          _vm.group.privacy &&
          _vm.group.privacy !== 'public'
        )?_c('app-group-private-warning'):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex === 0),expression:"tabIndex === 0"}]},[(
            _vm.userIsMember || _vm.userIsSuperAdmin || _vm.group.privacy === 'public'
          )?_c('div',[(_vm.userIsMember)?_c('app-post-prompt',{staticClass:"group-detail-view__post-prompt",attrs:{"for-groups":_vm.subgroups.length > 0 ? true : false,"group-id":_vm.id}}):_vm._e(),_c('app-group-post-list',{ref:"postList",attrs:{"group":_vm.group,"is-moderator":_vm.userIsModerator}})],1):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex === 1),expression:"tabIndex === 1"}]},[(_vm.userIsMember || _vm.group.privacy === 'public')?_c('div',[_c('div',[_c('app-group-members-list-card',{attrs:{"group":_vm.group,"role":"pending-member"}}),_c('app-group-members-list-card',{attrs:{"group":_vm.group,"role":"admin"}}),_c('app-group-members-list-card',{attrs:{"group":_vm.group,"role":"moderator"}}),_c('app-group-members-list-card',{attrs:{"group":_vm.group,"role":"member"}})],1)]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex === 2),expression:"tabIndex === 2"}]},[(_vm.group.id)?[(_vm.group.privacy === 'public' || _vm.userIsMember)?_c('app-group-subgroup-card',{attrs:{"can-create-subgroups":_vm.canCreateSubgroups,"group":_vm.group,"subgroups":_vm.subgroups}}):_vm._e()]:_vm._e()],2)],1)],1),_c('app-group-send-notification-modal',{attrs:{"show":_vm.showNotificationModal,"subgroups":_vm.subgroups,"group":_vm.group},on:{"close":function($event){_vm.showNotificationModal = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }