var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ch-link-preview"},[_vm._m(0),_c('div',{staticClass:"ch-link-preview_data"},[(!_vm.preview)?_c('i',{staticClass:"cs-icons-delete ch-attachment-delete",on:{"click":function($event){return _vm.$emit('remove')}}}):_vm._e(),_c('a',{staticClass:"ch-link-preview__content",attrs:{"href":_vm.src,"target":"_system"},on:{"click":function($event){$event.stopPropagation();return _vm.open()}}},[(_vm.picture && !_vm.hidePicture)?_c('div',{staticClass:"ch-link-preview__content-picture"},[_c('img',{attrs:{"src":_vm.picture,"alt":"picture"}})]):_vm._e(),_c('div',{class:[
          'ch-link-preview__content-textbox',
          {
            'ch-link-preview__content-textbox--nopicture':
              !_vm.picture && _vm.hidePicture,
          },
        ]},[(_vm.title)?_c('div',{staticClass:"cs-textstyle-detail-heading ch-link-preview__content-textbox--title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.description)?_c('div',{staticClass:"cs-textstyle-paragraph ch-link-preview__content-textbox--desc"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e(),_c('div',{staticClass:"cs-textstyle-link-text ch-link-preview__content-textbox--src"},[_vm._v(" "+_vm._s(_vm.src)+" ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ch-link-preview__top"},[_c('div',{staticClass:"ch-link-preview__top-icon"})])
}]

export { render, staticRenderFns }