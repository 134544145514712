var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ch-meeting-card-wrap"},[_c('cs-card',{staticClass:"ch-meeting-card",attrs:{"no-divider":"","media-position":"top"}},[_c('app-img',{staticClass:"meeting-image",attrs:{"slot":"media","src":_vm.meeting.picture},slot:"media"}),_c('div',{staticClass:"header",attrs:{"slot":"header"},slot:"header"},[_c('div',[_vm._v(_vm._s(_vm.meeting.name))])]),_c('div',{staticClass:"card-body",attrs:{"slot":"body"},slot:"body"},[_vm._v(" "+_vm._s(_vm.meeting.description)+" ")])],1),(_vm.meeting)?_c('div',{staticClass:"meeting-data"},[_c('div',{staticClass:"ch-meeting-meta-data"},[(_vm.meeting.myMembership)?_c('div',[(_vm.meeting.myMembership.role === 'INVITED')?_c('div',[_vm._v(" You are invited to this Meeting "),(_vm.meeting.myMembership.ticketStatus === 'FREE')?_c('div',[_vm._v(" Please accept your free ticket : "),_c('cs-button',{staticClass:"accept-btn",attrs:{"variant":"primary"},on:{"click":_vm.acceptInvite}},[_vm._v(" Accept ")])],1):_vm._e()]):_vm._e()]):_vm._e(),(
          _vm.userId &&
          (!_vm.meeting.myMembership ||
          _vm.meeting.myMembership.ticketStatus !== 'FREE')
        )?_c('div',{staticStyle:{"border":"dashed 1px #ccc","padding":"20px"}},[_c('div',{staticClass:"cs-textstyle-paragraph-small",staticStyle:{"margin-bottom":"10px"}},[_vm._v("Buy a ticket to attend this Meeting.")]),_c('cs-button',{attrs:{"variant":"primary","href":_vm.paymentUrl,"block":""}},[_vm._v(" Buy ticket: $"+_vm._s(_vm.meeting.ticketPrice)+" ")]),_c('img',{staticClass:"cc-logos",attrs:{"src":"https://chforum.backendcdn.com/us-west-2:5a31b81d-68e1-4f92-8e36-5d8c94f25048/3bf6d9b7-1019-45a1-8ba1-d3a6fac16af5.png"}}),_vm._m(0)],1):_vm._e(),(
          !_vm.userId && _vm.meeting.ticketPrice
        )?_c('div',{staticStyle:{"border":"dashed 1px #ccc","padding":"20px"}},[_c('div',{staticClass:"cs-textstyle-paragraph-small",staticStyle:{"margin-bottom":"10px"}},[_vm._v(" This is a ticketed meeting. You must be a CloutHub member to purchase a $"+_vm._s(_vm.meeting.ticketPrice)+" ticket. ")]),_c('cs-button',{attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.join()}}},[_vm._v(" Sign-In or Sign-Up ")])],1):_vm._e()])]):_vm._e(),_c('div',{staticClass:"cs-textstyle-paragraph-small-bold ch-people-list",staticStyle:{"text-align":"center"}},[_vm._v(" Hosts ")]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"center"}},[_c('app-avatar',{staticClass:"mb-4",attrs:{"picture":_vm.meeting.host.profilePhotoURL,"name":_vm.meeting.host.displayname,"variant":"primary","size":"small"}}),_c('div',[_vm._v(" "+_vm._s(_vm.meeting.host.username)+" ")])],1),_c('div',{},[_c('app-meeting-leave-button',{staticClass:"ch-leave-btn"})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs-textstyle-paragraph-small"},[_vm._v("For help, for questions about your transactions, or to request a refund, please contact the organization office at "),_c('a',{attrs:{"href":"mailto:support@clouthub.com"}},[_vm._v("support@clouthub.com")])])
}]

export { render, staticRenderFns }