import { render, staticRenderFns } from "./NewsSearch2.vue?vue&type=template&id=46097a78&scoped=true"
import script from "./NewsSearch2.vue?vue&type=script&lang=js"
export * from "./NewsSearch2.vue?vue&type=script&lang=js"
import style0 from "./NewsSearch2.vue?vue&type=style&index=0&id=46097a78&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46097a78",
  null
  
)

export default component.exports