<template>
  <div class="user-item">
    <div class="user-card">
      <app-avatar
        v-router-link="{ name: 'Profile', params: user }"
        class="ch-avatar cursor-pointer"
        :picture="user.profilePhotoURL"
        :name="user.firstname"
        variant="primary"
        size="medium"
      ></app-avatar>
      <div class="user-card-details">
        <div
          v-router-link="{ name: 'Profile', params: user }"
          class="cs-textstyle-paragraph-bold cursor-pointer"
        >
          <app-username :user="user" />
        </div>
        <div v-if="user.bio" class="cs-textstyle-paragraph-small user-card-bio">
          {{ user.bio }}
        </div>
        <div class="user-response-buttons">
          <app-follow-button
            v-if="!isFriend"
            :target="user"
            size="small"
            class="button-class"
            >Follow {{ user.username }}</app-follow-button
          >
          <app-friend-button
            v-if="isFriend"
            :target="user"
            size="small"
            class="button-class"
            >Friend {{ user.username }}</app-friend-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppAvatar from '@/components/general/Avatar.vue';
import AppFollowButton from '@/components/general/FollowButton.vue';
import AppFriendButton from '@/components/general/FriendButton.vue';
import AppUsername from '@/components/general/Username.vue';

export default {
  components: {
    AppAvatar,
    AppFollowButton,
    AppUsername,
    AppFriendButton,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    isFriend: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.user-item {
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 20px;
  position: relative;
}
.user-card {
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  height: 140px;
}
.user-card-details {
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow: hidden;
  flex: 1;
}

.user-card-bio {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.user-response-buttons {
  margin-top: auto;
}
.cursor-pointer {
  white-space: -moz-pre-wrap !important;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
  white-space: -webkit-pre-wrap;
  word-break: break-all;
  white-space: normal;
}
</style>
